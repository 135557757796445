import {
  Pressable,
  ScrollView,
  View,
  Share,
  ActivityIndicator,
  Platform,
  RefreshControl,
} from "react-native";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { PleaseWaitModal } from "../../components/modals";
import { ResponseModal } from "../../components/response-modal";
import SVGLoader from "../../../utils/SVGLoader";
import verticalmenu from "../../../assets/white-menu-vertical.svg";
import edit from "../../../assets/pencil.svg";
import profilelock from "../../../assets/Lock.svg";
import { Font } from "../../components/font";
import GroupMembers from "./group-members";
import CreateGroupPost from "./create-group-post";
import moment from "moment";
import colors from "../../../colors";
import { cloneDeep } from "lodash";
import {
  deleteAPost,
  getAPost,
  getGroupDetails,
  getGroupMembers,
  GetGroupsApi,
  LikePostApi,
  ShareAPost,
} from "../../services/api";
import { AuthContext } from "../../context/authContext";
import GroupPost from "../../components/group-components/GroupPost";
import EditModal from "../../components/group-components/modals/EditModal";
import GroupNavigations from "../../components/group-components/GroupNavigations";
import { useGroupsContext } from "../../context/groupsContext";
import { UserContext } from "../../context/userContext";

const ManageGroup = ({ route, navigation }) => {
  // const { tabStatus, setTabStatus } = useVendorFeedContext();
  const { userData } = useContext(UserContext);
  const { user, userToken } = useContext(AuthContext);
  const {
    groupTabStatus,
    setGroupTabStatus,
    memberGroups,
    setMemberGroups,
    adminGroups,
    setAdminGroups,
  } = useGroupsContext();
  const {
    id,
    name,
    description,
    isPrivate,
    checkRefreshList,
    isAdmin,
    icon_cover_image_url,
  } = route.params;
  const [isRefreshList, setRefreshList] = React.useState(true);
  const checkRefreshListforPosts = () => {
    setRefreshList(!isRefreshList);
  };
  const [isGroupHandlingTab, setIsGroupHandlingTab] = useState("home");
  const [isEditModal, setIsEditModal] = useState(false);
  const closeEditModal = () => setIsEditModal(false);
  const [MembersList, setMembersList] = useState([]);
  const [groupPost, setGroupPost] = useState([]);
  const [isPleaseWaitModal, setIsPleaseWaitModal] = useState(false);
  const [responseModal, setResponseModal] = useState({
    visible: false,
    message: "",
    DismissText: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isReLoading, setIsReLoading] = useState(false);
  const closeResponseModal = () => {
    setResponseModal((prev) => ({ ...prev, visible: false }));
  };

  const getGroupInfo = async (group_id) => {
    try {
      setIsLoading(true);
      let res = await getGroupDetails(group_id, userToken);
      if (res.status === 200) {
        setGroupPost(res.data.data);
        navigation.setParams({
          id: group_id,
          name: res.data?.data?.name,
          description: res.data?.data?.description,
          isPrivate: res.data?.data?.is_private,
          icon_cover_image_url: res.data?.data?.icon_cover_image_url,
          name: res.data?.data?.name,
        });
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    if (!name) getGroupInfo(id);
  }, [name]);

  const timeAgo = (updatedAt) => moment(updatedAt).fromNow();

  const GetMembers = async () => {
    try {
      let res = await getGroupMembers(id, userToken);
      if (res.status === 200) {
        // console.log(res.data.data, "res for get members 200");
        setMembersList(res.data.data);
        // console.log(res.data.data[0].user, "res for get members 200");
      } else console.log(res.data.data.user, "res for get members");
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };

  const getGroupPosts = async () => {
    try {
      setIsLoading(true);
      let res = await getAPost(id, userToken);
      // console.log(res, "res check for get posts");

      if (res.status === 200) {
        setGroupPost(res.data.posts);
        // console.log(res.data, "response check success for get posts");
      } else {
        console.log(res.data, "response check failed for get posts");
      }
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    } finally {
      setIsLoading(false);
    }
  };

  const sharePostApi = async (postId, index) => {
    try {
      const result = await Share.share({
        message: `https://smbexcustomer.wisdomtooth.tech/group-details?id=${id}`,
      });
      if (result?.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
          try {
            let res = await ShareAPost(id, postId, userToken);
            if (res.status === 201) {
              setGroupPost((data) => {
                data[index].shares++;
                return cloneDeep(data);
              });
              // setGroupPost(res.data.posts);
              // console.log(res.data, "response check success for share posts");
            } else {
              console.log(res.data, "response check failed for share posts");
            }
          } catch (error) {
            console.log("Error Getting share post : ", error);
          }
        }
      } else if (result?.action === Share.dismissedAction) {
        // dismissed
        console.log("Share action was dismissed.");
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const LikeDisLikePost = async (postId, isLiked, index) => {
    try {
      let res = await LikePostApi(id, postId, isLiked, userToken);
      console.log(res.data, res.status, "res check for Like posts");

      if (res.status === 201) {
        setGroupPost((data) => {
          const is_liked = isLiked;
          data[index].is_liked = is_liked ? false : true;
          if (is_liked) {
            data[index].likes--;
          } else {
            data[index].likes++;
          }
          return cloneDeep(data);
        });
      } else {
        console.log(
          res.data,
          res.status,
          "response check failed for Like posts data"
        );
      }
    } catch (error) {
      console.log("Error Getting share : ", error);
    }
  };

  const deletePost = async (postId) => {
    try {
      setIsPleaseWaitModal(true);
      let res = await deleteAPost(id, postId, userToken);
      console.log(res.data, res.status, "res check for delete posts");

      if (res.status === 204) {
        setGroupPost((prevList) => {
          prevList.filter((post) => post.id !== postId);
          return [...prevList];
        });
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Post Deleted Successfully !!!",
          DismissText: "Dismiss",
        });
      } else {
        setResponseModal({
          visible: true,
          message: res.data?.message
            ? res.data.message
            : "Something went wrong !!!",
          DismissText: "Dismiss",
        });
        setIsPleaseWaitModal(false);
        console.log(
          res.data,
          res.status,
          "response check failed for delete posts"
        );
      }
    } catch (error) {
      console.log("Error Getting for delete post  : ", error);
    }
  };

  const loadJoinedGroups = async () => {
    try {
      // setIsLoading(true);
      const res = await GetGroupsApi({ token: userToken, id: userData.id });
      if (res?.status == 200) {
        const members = [];
        const admins = [];
        res.data?.data?.forEach((group) => {
          group?.members?.forEach((member) => {
            if (member.group_role === "member") {
              members.push(group);
            } else if (member.group_role === "admin") {
              admins.push(group);
            }
          });
        });
        setMemberGroups({ data: members });
        setAdminGroups({ data: admins });
      } else if (res.data?.message) {
        setResponseModal((prev) => ({
          ...prev,
          visible: true,
          bodyText: res.data.message,
        }));
      } else {
        console.log("loadJoinedGroups response:", res.status, res.data);
      }
    } catch (error) {
      // setIsLoading(false);
      console.log("error?.code:", error, error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!adminGroups || !memberGroups) loadJoinedGroups();
  }, [adminGroups, memberGroups]);

  useEffect(() => {
    !isAdmin ? setIsGroupHandlingTab("home") : "";
    getGroupPosts();
    GetMembers();
  }, [isRefreshList, id]);

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {/* --------------------------mobile-screen Group Navbar--------------------------- */}
      {isPleaseWaitModal && <PleaseWaitModal showLoader={true} />}
      <ResponseModal
        visible={responseModal.visible}
        bodyText={responseModal.message}
        onRequestClose={closeResponseModal}
      ></ResponseModal>
      <View className="p-4 pt-2 w-full flex lg:hidden bg-app-e">
        <View className="w-full flex-col">
          {/* <View className="w-3 h-3">
              <SVGLoader
              src={leftArrow}
              height={40}
              width={40}
                webHeight={"20em"}
                webWidth={"auto"}
                />
            </View> */}
          {/* <View className="flex-row justify-end items-center">
            {/* <View className="w-5 h-5">
                <SVGLoader
                  src={bell}
                  height={40}
                  width={40}
                  webHeight={"20em"}
                  webWidth={"auto"}
                />
              </View> *
            <View className="w-5 h-5 mb-2 mr-2">
              <SVGLoader
                src={verticalmenu}
                height={30}
                width={25}
                webHeight={"20em"}
                webWidth={"auto"}
              />
            </View>
          </View> */}
          <EditModal
            navigation={navigation}
            visible={isEditModal}
            onRequestClose={closeEditModal}
            userId={user.id}
            groupId={id}
            name={name}
            description={description}
            activetab={isPrivate == true ? "Private" : "Public"}
            checkRefreshList={checkRefreshList}
          ></EditModal>
          <View className="w-full justify-center items-center">
            <View
              className="mb-2 flex-col rounded-lg"
              style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
            >
              <View className="px-6 py-1 flex-col justify-center items-center">
                <Font className="text-lg text-app-a font-bold">
                  {/* Relax Baby sitting */}
                  {name}
                </Font>
                <Font className="text-sm text-app-a">{description}</Font>
                {/* <Font className="text-sm text-app-a">just relax</Font> */}
              </View>
              {!!isAdmin ? (
                <View className="flex-row justify-end">
                  <Pressable
                    onPress={() => setIsEditModal(true)}
                    className="px-2 flex-row justify-end items-center rounded-full"
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                  >
                    <Font className="text-xs text-app-a mr-2">Edit</Font>
                    <View className="w-3 h-3 flex justify-center items-center">
                      <SVGLoader
                        src={edit}
                        height={10}
                        width={10}
                        webHeight={"20em"}
                        webWidth={"auto"}
                      />
                    </View>
                  </Pressable>
                </View>
              ) : (
                <></>
              )}
            </View>
            <View className="flex-row justify-center item-center">
              <View className="mr-2 w-3 h-3 flex justify-center item-center">
                <SVGLoader
                  src={profilelock}
                  height={20}
                  width={20}
                  webHeight={"20em"}
                  webWidth={"auto"}
                />
              </View>
              <Font className="text-xs text-app-a">
                {isPrivate == true ? "Private" : "Public"} group .{" "}
                {MembersList?.length} members
              </Font>
            </View>
          </View>
        </View>
      </View>

      <ScrollView
        className="rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={isReLoading}
              onRefresh={() => {
                setIsReLoading(true);
                if (isGroupHandlingTab === "home") {
                  getGroupPosts();
                }
                if (isGroupHandlingTab === "members") {
                  GetMembers();
                }
                setTimeout(() => setIsReLoading(false), 1000);
              }}
            />
          )
        }
      >
        <View className=" max-w-screen-xl w-full lg:py-4 h-full ">
          <View className="flex flex-col lg:flex-row max-w-screen-xl lg:justify-between w-full">
            <View className="hidden lg:flex lg:flex-row max-w-screen-xl w-full lg:w-[23%]">
              <GroupNavigations
                adminList={adminGroups?.slice(0, 4)}
                memberList={memberGroups?.slice(0, 4)}
                groupTab={groupTabStatus.curr}
                setGroupTab={(value) => {
                  setGroupTabStatus({ curr: value, prev: groupTabStatus.curr });
                  navigation.navigate("groups", { tab: value });
                }}
                showTopTabs={false}
                checkRefreshList={checkRefreshList}
              />
            </View>
            {/* second column for web */}
            <View className="max-w-screen-lg w-full lg:w-[73%] xl:w-[77%] h-full lg:px-4">
              {/* ------------------------------------------------lg-screen header--------------------------------------------- */}
              <View className="p-4 pt-2 w-full hidden lg:flex bg-app-e rounded-lg">
                <View className="w-full flex-col">
                  <View className="w-full flex-row justify-end items-center">
                    <View className="w-5 h-5 mb-2">
                      <SVGLoader
                        src={verticalmenu}
                        height={40}
                        width={40}
                        webHeight={"20em"}
                        webWidth={"auto"}
                      />
                    </View>
                  </View>
                  <View className="w-full justify-center items-center">
                    <View
                      className="mb-2 flex-col rounded-lg"
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                    >
                      <View className="px-10 py-1 flex-col justify-center items-center">
                        <Font className="text-lg text-app-a font-bold">
                          {name}
                        </Font>
                        <Font className="text-sm text-app-a">
                          {description}
                        </Font>
                      </View>
                      {!!isAdmin ? (
                        <View className="flex-row justify-end">
                          <Pressable
                            className="px-2 flex-row justify-end items-center rounded-full"
                            style={{
                              backgroundColor: "rgba(255, 255, 255, 0.5)",
                            }}
                            onPress={() => setIsEditModal(true)}
                          >
                            <Font className="text-xs text-app-a mr-2">
                              Edit
                            </Font>
                            <View className="w-3 h-3">
                              <SVGLoader
                                src={edit}
                                height={40}
                                width={40}
                                webHeight={"20em"}
                                webWidth={"auto"}
                              />
                            </View>
                          </Pressable>
                        </View>
                      ) : (
                        <></>
                      )}
                    </View>
                    <View className="flex-row justify-center item-center">
                      <View className="mr-2 w-3 h-3 flex justify-center item-center">
                        <SVGLoader
                          src={profilelock}
                          height={40}
                          width={40}
                          webHeight={"20em"}
                          webWidth={"auto"}
                        />
                      </View>
                      <Font className="text-xs text-app-a">
                        {isPrivate == true ? "Private" : "Public"} group .{" "}
                        {MembersList?.length} members
                      </Font>
                    </View>
                  </View>
                </View>
              </View>

              {/* ------------------------Group-Tabs----------------------------------- */}
              <View className="w-full flex-col rounded-lg">
                {!!isAdmin ? (
                  <View className="py-4 px-4 w-full flex-row justify-center overflow-auto">
                    <Pressable
                      className={`py-2 px-2 m-1 mr-0.5 lg:m-2 min-w-max  border border-gray-200 rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg ${
                        isGroupHandlingTab === "home" ? "bg-app-e" : "bg-none"
                      }`}
                      onPress={() => {
                        setIsGroupHandlingTab("home");
                      }}
                    >
                      <Font
                        className={`text font-medium self-center justify-self-center ${
                          isGroupHandlingTab === "home"
                            ? "text-app-a"
                            : "text-app-d1"
                        }`}
                        weight={600}
                      >
                        Home
                      </Font>
                    </Pressable>
                    <Pressable
                      className={`py-2 px-3 m-1 mx-0.5 lg:m-2 min-w-max flex border border-gray-200  rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg ${
                        isGroupHandlingTab === "create-post"
                          ? "bg-app-e"
                          : "bg-none"
                      }`}
                      onPress={() => {
                        setIsGroupHandlingTab("create-post");
                      }}
                    >
                      <Font
                        className={`text font-medium self-center justify-self-center ${
                          isGroupHandlingTab === "create-post"
                            ? "text-app-a "
                            : "text-app-d1"
                        }`}
                        weight={600}
                      >
                        Create a Post
                      </Font>
                    </Pressable>
                    <Pressable
                      className={`py-2 px-3 m-1 mx-0.5 lg:m-2 min-w-max flex border border-gray-200  rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg ${
                        isGroupHandlingTab === "members"
                          ? "bg-app-e"
                          : "bg-none"
                      }`}
                      onPress={() => {
                        setIsGroupHandlingTab("members");
                      }}
                    >
                      <Font
                        className={`text font-medium self-center justify-self-center ${
                          isGroupHandlingTab === "members"
                            ? "text-app-a "
                            : "text-app-d1"
                        }`}
                        weight={600}
                      >
                        Members
                      </Font>
                    </Pressable>
                  </View>
                ) : (
                  <></>
                )}

                {/* ------------------------------Home Tab Section------------------------------ */}

                {isGroupHandlingTab === "home" && (
                  <View
                    className={`w-full max-w-xl self-center ${
                      !isAdmin ? "mt-4" : ""
                    }`}
                  >
                    {isLoading ? (
                      <ActivityIndicator
                        className="self-center justify-self-center flex-1 w-full h-full"
                        color={colors.app.e}
                        size="small"
                      />
                    ) : !groupPost?.length ? (
                      <View className="py-4 flex-col justify-center items-center">
                        <Font>No posts yet</Font>
                      </View>
                    ) : (
                      groupPost.slice().map((e, i) => (
                        <GroupPost
                          attachedFileURL={e?.attached_file_url}
                          key={e?.id + "" + i}
                          postId={e?.id}
                          groupId={id}
                          postIconURL={icon_cover_image_url}
                          postCoverImageURL={e?.cover_image_url}
                          postTitle={e?.title || name}
                          postTimestamp={e?.created_at}
                          postDescription={e?.description}
                          externalLinkUrl={e?.external_link_url}
                          postLikes={e?.likes}
                          isPostLiked={e?.is_liked}
                          postComments={e?.comments}
                          postShares={e?.shares}
                          onLikeGroupPost={({ postId, isLiked }) =>
                            LikeDisLikePost(postId, isLiked, i)
                          }
                          onSharePost={(id) => sharePostApi(id, i)}
                          allowPostEdit={isAdmin}
                          onDeletePost={deletePost}
                          onEditPost={(postData) => {
                            setGroupPost((posts) => {
                              const index = posts.findIndex(
                                (p) => p.id === postData.id
                              );
                              if (index > -1) {
                                posts[index] = {
                                  ...posts[index],
                                  ...postData,
                                };
                              }
                              return [...posts];
                            });
                          }}
                        />
                      ))
                    )}
                  </View>
                )}

                {/* ---------------------Create Post tab Section -------------------------- */}
                {isGroupHandlingTab === "create-post" && (
                  <CreateGroupPost
                    navigation={navigation}
                    groupId={id}
                    userToken={userToken}
                    groupName={name}
                    checkRefreshListforPosts={checkRefreshListforPosts}
                    onPostCreate={() => {
                      getGroupPosts();
                      setIsGroupHandlingTab("home");
                    }}
                  />
                )}

                {/* -----------------------Group-members Tab Section----------------------------- */}
                {isGroupHandlingTab === "members" && (
                  <GroupMembers
                    navigation={navigation}
                    MembersLists={MembersList}
                    userToken={userToken}
                    id={user.id}
                    groupId={id}
                  />
                )}

                {/* -----------------------Group settings tab section------------------------------ */}
                {/* {isGroupHandlingTab === "settings" && (
                <GroupSettings
                  navigation={navigation}
                  groupId={id}
                  checkRefreshList={checkRefreshList}
                  userId={user.id}
                  isPrivate={isPrivate}
                  groupStatus={groupStatus}
                />
              )} */}
              </View>
            </View>
            {/* <Footer navigation={navigation} /> */}
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default ManageGroup;
