import React, { useContext, useEffect, useState } from "react";
import { Image, Pressable, ScrollView, View } from "react-native";
import {
  deleteBookmark,
  DEV_API,
  getBookmark,
  getListing,
  getProducts,
  postBookmark,
  SharedContext,
} from "../../services/api";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import RemixIcon from "react-native-remix-icon";
import { Maps } from "../../components/maps";
import colors from "../../../colors";
import { BusinessesContext } from "../../context/businessesContext";
import { AuthContext } from "../../context/authContext";
import { UserContext } from "../../context/userContext";
import { ActivityIndicator } from "react-native";
import { RefreshControl } from "react-native-gesture-handler";
import { ProductsContext } from "../../context/productsContext";
import { Widget } from "../../components/widget";
import { Button } from "../../components/input";

export const Search = ({ navigation, route }) => {
  const [shared, setShared] = useContext(SharedContext);
  const { userWishlists, setUserWishlists, setIsUserLoading, isUserLoading } =
    useContext(UserContext);
  const { user, userToken } = useContext(AuthContext);
  const { isBusinessLoading, setIsBusinessLoading, businesses, setBusinesses } =
    useContext(BusinessesContext);
  const { isProductsLoading, setIsProductsLoading, products, setProducts } =
    useContext(ProductsContext);
  const [bookmarks, setBookmarks] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [pagination, setPagination] = useState({
    from: null,
    to: null,
    total_records: null,
  });
  const [searchParams, setSearchParams] = useState({
    itemType: "Listing",
    keyword: "",
    category: undefined,
    priceRange: [],
    minPrice: 0,
    maxPrice: undefined,
    location: "",
    detailLocation: "",
    // sortBy: "",
  });
  const listingPerPage = 10;
  useEffect(() => {
    setShared((e) => ({ ...e, search: searchParams }));
  }, [searchParams]);
  const getData = async () => {
    try {
      setRefreshing(true);
      setIsBusinessLoading(true);
      const res = await getListing({
        itemType: route.params.itemType,
        searchText: route.params.keyword,
        listingCategoryId:
          route.params.category === undefined
            ? route.params.category
            : route.params.category?.value === "all"
            ? undefined
            : route.params.category.value,
        minPrice: Number(route.params.minPrice),
        maxPrice:
          route.params.maxPrice === undefined
            ? route.params.maxPrice
            : route.params.maxPrice === "0"
            ? undefined
            : Number(route.params.maxPrice),
        // sortBy: route.params.sortBy,
        offset: pagination.to === null ? 0 : pagination.to + 1,
        limit:
          pagination.to + listingPerPage <= pagination.total_records
            ? listingPerPage || 50
            : pagination.total_records || 50,
      });
      if (res.status === 200) {
        setBusinesses(res.data);
        setPagination({ from: 0, to: 10, total_records: res.data.total_count });
      }
    } catch (error) {
      console.log("search, getlisting error : ", error);
    } finally {
      setRefreshing(false);
      setIsBusinessLoading(false);
    }
  };
  const getAllProducts = async () => {
    try {
      setRefreshing(true);
      setIsProductsLoading(true);
      const res = await getProducts({
        // itemType: route.params.itemType,
        searchText: route.params.keyword,
        productCategoryId:
          route.params.category === undefined
            ? route.params.category
            : route.params.category?.value === "all"
            ? undefined
            : route.params.category.value,
        minPrice: Number(route.params.minPrice),
        maxPrice:
          route.params.maxPrice === undefined
            ? route.params.maxPrice
            : route.params.maxPrice === "0"
            ? undefined
            : Number(route.params.maxPrice),
        // sortBy: route.params.sortBy,
      });
      if (res.status === 200) {
        setProducts(res.data.data);
      }
    } catch (error) {
      console.log("search, getproducts error : ", error);
    } finally {
      setRefreshing(false);
      setIsProductsLoading(false);
    }
  };
  useEffect(() => {
    setSearchParams((e) => ({ ...e, ...route.params }));
    if (route.params.itemType === "Listing") {
      getData();
    } else {
      getAllProducts();
    }
  }, []);

  const getWishlist = async (token) => {
    try {
      setRefreshing(true);
      setIsUserLoading(true);
      const res = await getBookmark({ token });
      if (res.status === 200) {
        setUserWishlists(res.data.data);
        const wishlistArray = res.data.data.map((e) => {
          return { id: e.id, listingId: e.listing.id };
        });
        setBookmarks(wishlistArray);
      } else {
        console.log("search, getWishlist error : ", res.data);
      }
    } catch (error) {
      console.log("search, getWishlist error : ", error);
    } finally {
      setRefreshing(false);
      setIsUserLoading(false);
    }
  };
  useEffect(() => {
    if (user && userToken !== null) {
      getWishlist(userToken);
    }
  }, [user, userToken]);

  const onBookmark = async ({ id }) => {
    try {
      const filterBookmark = bookmarks.filter((e) => {
        if (e.listingId === id) return e;
      });
      if (filterBookmark.length === 1) {
        const res = await deleteBookmark({
          bookmark_id: filterBookmark[0].id,
          token: userToken,
        });
        if (res.status === 204) {
          const newArray = bookmarks.filter((e) => {
            if (e.listingId !== id) return e;
          });
          const newArray1 = userWishlists.filter((e) => {
            if (e.listing.id !== id) return e;
          });
          setBookmarks(newArray);
        } else {
          console.log("search, deletebookmark res err : ", res.data);
        }
      } else {
        const res = await postBookmark({ id, token: userToken });
        if (res.status === 201) {
          setBookmarks((e) => [...e, { id: res.data.data.id, listingId: id }]);
        } else {
          console.log("search, onbookmark add res err : ", res.data);
        }
      }
    } catch (error) {
      console.log("search, onbookmark err : ", error);
    }
  };

  // --------------- if loading -------------------
  // if (isBusinessLoading || isUserLoading) {
  //   return (
  //     <View className="flex-1 justify-center items-center">
  //       <Font> Loading Data...</Font>
  //     </View>
  //   );
  // }

  const onGetDetails = ({ id }) => {
    navigation.navigate("listing", { id });
  };
  const goToProductDetails = (id) => {
    navigation.navigate("product", { id });
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-app-b">
      {searchParams.itemType === "Listing" ? (
        <View className="flex-1 flex-col lg:flex-row">
          {/* <Animated.View
            className="w-full lg:w-1/2 h-full flex lg:hidden"
            style={{
              height: heightAnim.interpolate({
                inputRange: [0, 1],
                outputRange: ["0%", "100%"],
              }),
            }}
          >
            <Maps className="flex-1"></Maps>
          </Animated.View> */}

          <View className="w-full flex-1 lg:h-full mt-5 lg:mt-0 bg-app-b rounded-t-2xl lg:rounded-none ">
            {/* <View className="w-full h-10 flex lg:hidden">
              <Pressable
                className="flex-1 items-center justify-center"
                onPress={toggleMap}
              >
                <View className="h-1 w-16 bg-app-d3 rounded"></View>
              </Pressable>
            </View> */}
            <ScrollView
              className="flex-1 h-4"
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={() => {
                    getData();
                    getWishlist();
                  }}
                />
              }
            >
              <View className="flex-1 h-full w-full py-5 px-7 items-center justify-center">
                <View className="flex-row w-full my-5 hidden lg:flex ">
                  {/* <Input
                    type="text"
                    placeholder="What are you looking for?"
                    className="flex-1"
                  ></Input>
                  <Input placeholder="Location" className="flex-1"></Input> */}

                  <Pressable
                    className="bg-app-a rounded flex-1 p-4 border border-gray-200 flex-row items-center"
                    onPress={() =>
                      navigation.navigate("search-modal", searchParams)
                    }
                  >
                    <View className="mr-4">
                      <RemixIcon
                        name="search-line"
                        size={24}
                        color={colors.app.d}
                      ></RemixIcon>
                    </View>
                    <View className="">
                      <Font weight={600} className=" text-app-d text-[15px]">
                        {searchParams.keyword !== ""
                          ? searchParams.keyword
                          : searchParams.category?.label ?? "All"}
                      </Font>
                    </View>
                  </Pressable>
                </View>
                {/* <View className="w-full">
                    <Select
                      options={[
                        { label: "test", value: "test" },
                        { label: "test 2", value: "test 2" },
                        { label: "test 3", value: "test 3" },
                      ]}
                      placeholder="Select"
                      className="w-full mb-4"
                      mode="simple"
                    ></Select>
                  </View> */}
                {businesses.total_count > 0 &&
                !isBusinessLoading &&
                !isUserLoading ? (
                  businesses.data.map((e, i) => (
                    <Pressable
                      className="w-full bg-app-a active:bg-app-c1 rounded-lg border border-gray-200 flex-row mb-4"
                      key={`business_data_${i}`}
                      onPress={() => onGetDetails({ id: e.id })}
                    >
                      <View className="w-32 h-32 lg:h-40 lg:w-40 flex overflow-hidden rounded-md">
                        <Image
                          source={
                            e.cover_image_url
                              ? {
                                  uri: `${DEV_API}/media/${e.cover_image_url}`,
                                }
                              : require("../../../assets/bg.jpg")
                          }
                          className="w-full h-full object-contain"
                        ></Image>
                        {user && (
                          <View className="absolute top-0 left-0 w-full h-full p-1 flex-row">
                            <Pressable
                              className="h-8 w-8 bg-app-a rounded-full items-center justify-center flex lg:hidden"
                              onPress={() => onBookmark({ id: e.id })}
                            >
                              {() => {
                                const isBookmark = bookmarks.filter((ee) => {
                                  if (ee.listingId === e.id) return e;
                                });
                                return (
                                  <RemixIcon
                                    name={
                                      isBookmark.length === 1
                                        ? "bookmark-fill"
                                        : "bookmark-line"
                                    }
                                    color={colors.app.e}
                                    size="18"
                                  ></RemixIcon>
                                );
                              }}
                            </Pressable>
                            <View className="flex-1"></View>
                          </View>
                        )}
                      </View>
                      <View className="justify-start px-4 py-1 flex-1">
                        <View className="flex-row items-center pb-1">
                          <Font
                            className="text-xs lg:text-md text-app-f"
                            numberOfLines={1}
                            weight={500}
                          >
                            {e.listing_category.title}
                          </Font>
                        </View>
                        <View className="flex pb-3">
                          <Font
                            className="text-[15px] text-app-d leading-4"
                            numberOfLines={2}
                            weight={600}
                          >
                            {e.title}
                          </Font>
                        </View>
                        <View className="flex-row items-center pb-1">
                          <RemixIcon
                            name="map-pin-fill"
                            color={colors.app.d1}
                            size={20}
                            className="mr-1"
                          ></RemixIcon>

                          {e.city !== null &&
                          e.state !== null &&
                          e.country !== null ? (
                            <Font
                              className=" text-app-d1"
                              weight={500}
                              numberOfLines={1}
                            >
                              {e.city + ", " + e.state + ", " + e.country}
                            </Font>
                          ) : (
                            <Font
                              className=" text-app-d1"
                              weight={500}
                              numberOfLines={1}
                            >
                              city, state, country
                            </Font>
                          )}
                        </View>
                        <View className="flex-row items-center pb-1">
                          <RemixIcon
                            name="star-fill"
                            size={16}
                            color={colors.app.i}
                            className="mr-1 flex-shrink-0"
                          ></RemixIcon>
                          <Font
                            className="text-app-d1"
                            weight={500}
                            numberOfLines={1}
                          >
                            {" "}
                            {`${
                              e.total_reviews >= 1
                                ? (e.one_star_ratings +
                                    e.two_star_ratings +
                                    e.three_star_ratings +
                                    e.four_star_ratings +
                                    e.five_star_ratings) /
                                  e.total_reviews
                                : 0
                            } / 5`}{" "}
                            ({e.total_reviews}){" "}
                            {`${e.total_reviews === 1 ? "review" : "reviews"}`}
                          </Font>
                        </View>
                        <View className="flex-row items-center">
                          <Font
                            className=" text-app-d"
                            numberOfLines={1}
                            weight={600}
                          >
                            {" "}
                            $ {e.min_price / 100 ?? 0}
                            {" -  "}$ {e.max_price / 100 ?? 0}{" "}
                          </Font>
                        </View>
                      </View>
                      {user && (
                        <View className="hidden lg:flex p-4">
                          <Pressable onPress={() => onBookmark({ id: e.id })}>
                            {() => {
                              const isBookmark = bookmarks.filter((ee) => {
                                if (ee.listingId === e.id) return e;
                              });
                              return (
                                <RemixIcon
                                  name={
                                    isBookmark.length === 1
                                      ? "bookmark-fill"
                                      : "bookmark-line"
                                  }
                                  color={colors.app.e}
                                  size="24"
                                ></RemixIcon>
                              );
                            }}
                          </Pressable>
                        </View>
                      )}
                    </Pressable>
                  ))
                ) : businesses.total_count === 0 &&
                  !isBusinessLoading &&
                  !isUserLoading ? (
                  <View className="flex-1 justify-center items-center">
                    <Font>No Businesses Found</Font>
                  </View>
                ) : (
                  <View className="flex-1 w-full justify-center h-40">
                    <ActivityIndicator
                      size={"small"}
                      color={colors.app.e}
                    ></ActivityIndicator>
                  </View>
                )}
              </View>
              {pagination.total_records > listingPerPage && (
                <View className="flex-1 px-4 py-1">
                  <Button label="Load more" type="primary"></Button>
                </View>
              )}

              {/* <Footer></Footer> */}
            </ScrollView>
          </View>
          <View className="w-full lg:w-1/3 h-full hidden lg:flex mt-5">
            {/* <Maps className="flex-1"></Maps> */}
            <View className="w-3/4">
              <Widget></Widget>
            </View>
          </View>
        </View>
      ) : (
        <View className="p-2 rounded-t-2xl bg-app-b h-full">
          <View className="flex-1 w-full lg:flex-row h-full">
            <ScrollView className="flex-1 w-full h-full px-5">
              <View className="my-5 hidden lg:flex">
                {/* <Font className="text-lg font-semibold text-app-d ">
                                Search
                            </Font> */}
                <Pressable
                  className="bg-app-a rounded flex-1 p-4 border border-gray-200 flex-row items-center"
                  onPress={() => {
                    navigation.navigate("search-modal", searchParams);
                  }}
                >
                  <View className="mr-4">
                    <RemixIcon
                      name="search-line"
                      size={24}
                      color={colors.app.d1}
                    ></RemixIcon>
                  </View>
                  <View className="">
                    <Font weight={600} className=" text-app-d text-[15px]">
                      {searchParams.keyword !== ""
                        ? searchParams.keyword
                        : searchParams.category?.label ?? "All"}
                    </Font>
                  </View>
                </Pressable>
              </View>
              {products.length >= 1 && !isProductsLoading ? (
                <View className="flex-row w-full p-0 lg:pr-4 flex-wrap">
                  {products?.map((e, i) => (
                    <View className="w-1/2 lg:w-1/4 p-2" key={i}>
                      <Pressable
                        className="w-full rounded-lg overflow-hidden border border-gray-200 bg-app-a shadow-black shadow-lg  active:bg-app-c1"
                        onPress={() => goToProductDetails(e.id)}
                      >
                        <View className="h-44 lg:h-64 w-full">
                          <Image
                            source={
                              e.cover_image_url
                                ? {
                                    uri: `${DEV_API}/media/${e.cover_image_url}`,
                                  }
                                : require("../../../assets/bip3.jpg")
                            }
                            className="w-full h-full"
                          ></Image>
                        </View>
                        <View className="p-2">
                          <Font
                            className="text-md text-app-d mb-1 leading-5"
                            weight={600}
                            numberOfLines={2}
                          >
                            {e.title}
                          </Font>

                          <View
                            className="flex-row items-center"
                            style={{
                              marginVertical: 2,
                            }}
                          >
                            <Font
                              className="text-[15px] text-app-d"
                              numberOfLines={1}
                              weight={700}
                            >
                              {" "}
                              $ {e.price / 100 || e.sale_price / 100}{" "}
                            </Font>
                            <Font
                              className="text-md text-app-e1 line-through ml-2"
                              weight={600}
                              numberOfLines={1}
                            >
                              {" "}
                              $ {e.price / 100 || e.sale_price / 100}
                            </Font>
                          </View>
                          <View
                            className="flex-row items-center"
                            style={{
                              marginVertical: 2,
                            }}
                          >
                            <RemixIcon
                              name="star-fill"
                              size={16}
                              color={colors.app.i}
                              className="mr-1 flex-shrink-0"
                            ></RemixIcon>
                            <Font
                              className="text-md text-app-d1"
                              weight={500}
                              numberOfLines={1}
                            >
                              {" "}
                              {e.product_details.average_rating} |{" "}
                              {e.product_details.total_reviews} reviews
                            </Font>
                          </View>
                        </View>
                      </Pressable>
                    </View>
                  ))}
                </View>
              ) : products.length === 0 && !isProductsLoading ? (
                <View className="flex-1 w-full justify-center items-center">
                  <Font>No products found.</Font>
                </View>
              ) : (
                <View className="flex-1 w-full justify-center h-40">
                  <ActivityIndicator
                    size={"small"}
                    color={colors.app.e}
                  ></ActivityIndicator>
                </View>
              )}
            </ScrollView>
            <View className="w-full lg:w-1/3 h-full hidden lg:flex mt-5">
              {/* <Maps className="flex-1"></Maps> */}
              <View className="w-3/4">
                <Widget></Widget>
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};
