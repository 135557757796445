import React, { useContext, useEffect, useState } from "react";
import {
  Pressable,
  View,
  Image,
  TouchableOpacity,
  Alert,
  Text,
  Platform,
  StyleSheet,
  Modal,
} from "react-native";

import * as ImagePicker from "expo-image-picker";
import * as DocumentPicker from "expo-document-picker";
import { ScrollView } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import RemixIcon from "react-native-remix-icon";
import { PleaseWaitModal } from "../../components/modals";
import { ResponseModal } from "../../components/response-modal";
import { Font } from "../../components/font";
import {
  createCrossPlatformFormData,
  fileURLtoBlob,
} from "../../../utils/encDec";
import { DEV_API } from "../../services/api";
import axios from "axios";
import { AuthContext } from "../../context/authContext";
import CameraCustomView from "../../components/CameraCustomView";

export default function CreateGroupPost({
  navigation,
  route,
  groupId,
  groupName,
  checkRefreshListforPosts,
  onPostCreate,
}) {
  const [postDescription, setIsPostDescription] = useState("");
  const [postExternalURL, setPostExternalURL] = useState("");
  const { user, userToken } = useContext(AuthContext);
  const [stats, setStats] = React.useState({
    total_listings: 0,
    total_products: 0,
    bookings_completed: 0,
    orders_fulfilled: 0,
  });
  const [activities, setActivities] = React.useState([]);
  const [showCameraView, setShowCameraView] = React.useState(false);

  useEffect(() => {
    getActivities();
  }, []);

  const getActivities = async () => {
    try {
      let res = await getStats();
      if (res.status === 200) setStats(res.data.data);
      else
        setStats({
          total_listings: 0,
          total_products: 0,
          bookings_completed: 0,
          orders_fulfilled: 0,
        });
      let activities = await getRecentActivites();
      if (activities.status === 200) setActivities(activities.data.data || []);
    } catch (error) {
      // console.log("Error Getting Stats : ", error.response);
    }
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [selectedFileObj, setSelectedFileObj] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageObj, setSelectedImageObj] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isResponseModal, setIsResponseModal] = useState({
    visible: false,
    message: "",
  });

  const openFilePicker = async () => {
    const result = await DocumentPicker.getDocumentAsync({
      type: "*/*",
      copyToCacheDirectory: true,
    });
    if (result.type !== "cancel") {
      let path = result.uri.replace("file:///", "file:/");
      setSelectedFileName(result.name);
      setSelectedFile(result.uri);
      // console.log(selectedFile, result, "selectedFile");
      fileURLtoBlob(Platform.OS === "ios" ? path : result.uri)
        .then((data) => {
          setSelectedFileObj({
            uri: result.uri,
            name: result.name,
            type: result.mimeType,
            blob: data,
          });
        })
        .catch((error) => {
          console.log("fileURLtoBlob-error", error);
        });
    }
  };

  const closeResponseModal = () => {
    setIsResponseModal((prev) => ({ ...prev, visible: false }));
  };
  const openGallery = async () => {
    let permission = await ImagePicker.getMediaLibraryPermissionsAsync();
    if (permission.granted)
      if (permission.canAskAgain)
        permission = await ImagePicker.requestMediaLibraryPermissionsAsync();
      else
        setIsResponseModal({
          message: "Media Library Permissions Blocked",
          visible: true,
        });
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 0.8,
      allowsEditing: true,
    });

    if (!result.canceled) {
      let imageObject = result?.assets[0];
      let path = imageObject?.uri?.replace("file:///", "file:/");
      setSelectedImage(result.assets[0].uri);
      if (result.assets[0].uri !== null) {
        fileURLtoBlob(Platform.OS === "ios" ? path : imageObject?.uri)
          .then((data) => {
            imageObject.blob = data;
            setSelectedImageObj(imageObject);
          })
          .catch((error) => {
            console.log("fileURLtoBlob-error", error);
          });
      }
    }
  };

  const openCamera = async () => {
    setShowCameraView(true);
  };

  const CreatePostForGroup = async () => {
    try {
      setIsLoading(true);
      const dataObj = {};
      dataObj.title = groupName;
      dataObj.external_link_url = postExternalURL;
      dataObj.description = postDescription;
      dataObj.cover_image = selectedImageObj;
      dataObj.attached_file = selectedFileObj;

      const formData = createCrossPlatformFormData({
        blobDataKeys: ["cover_image", "attached_file"],
        data: dataObj,
      });

      if (Object.keys(dataObj)?.length) {
        const res = await axios.post(
          DEV_API + `/groups/${groupId}/posts`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("post Creation ", res.data, res.status);
        if (res.status == 201) {
          setSelectedImage(null);
          setIsPostDescription("");
          setPostExternalURL("");
          setSelectedFile(null);
          setIsLoading(false);
          setIsResponseModal((prev) => ({
            ...prev,
            visible: true,
            message: "Post Successfully Created !!!",
          }));
          // checkRefreshListforPosts();
          onPostCreate();
        } else {
          setIsLoading(false);
          setIsResponseModal((prev) => ({
            ...prev,
            visible: true,
            message: res?.data?.message || "Post Creation Failed !!!",
          }));
        }
      }
    } catch (error) {
      console.log(
        "post Creation Failed",
        error,
        error?.response?.status,
        error?.response?.data
      );
      setIsLoading(false);
      setIsResponseModal((prev) => ({
        ...prev,
        visible: true,
        message:
          error?.response?.data?.message ||
          "Post creation failed due to an unknown error",
      }));
    }
  };

  return (
    <ScrollView
      className="p-4 rounded-t-2xl bg-gray-50"
      contentContainerStyle={{
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isLoading && <PleaseWaitModal showLoader={true} />}
      <ResponseModal
        modalVisible={isResponseModal.visible}
        onPrimaryButtonPress={closeResponseModal}
        modalContent={isResponseModal.message}
        primaryButtonLabel={"Dismiss"}
      ></ResponseModal>

      <View className="max-w-screen-lg w-full h-full">
        <View className="flex max-w-screen-lg w-full flex-col justify-center items-center">
          {/* ------------------------Create Group-Tab----------------------------------- */}
          <View className=" w-full flex p-4 item-center bg-app-a">
            <TouchableOpacity className="flex-row justify-end items-center">
              {/* {selectedImage != "" && selectedImage != null && (
                    <View className="mr-10">
                      <Pressable
                        onPress={() => setSelectedImage(null)}
                        style={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          zIndex: 9999,
                          backgroundColor: "white",
                        }}
                        className={`w-3 rounded-full justify-center items-center`}
                      >
                        <Text style={{ fontSize: 10, fontWeight: "600" }}>
                          X
                        </Text>
                      </Pressable>
                      <Image
                        // source={require("../../../assets/user.jpg")}
                        source={{ uri: selectedImage }}
                        className="w-8 h-8 rounded-lg"
                      />
                    </View>
                  )} */}
              <Pressable
                onPress={() =>
                  postDescription == "" ? null : CreatePostForGroup()
                }
                className={`px-2 py-1 flex-center items-center ${
                  postDescription == ""
                    ? "bg-gray-100 border border-gray-600"
                    : "bg-green-100 border border-green-600"
                } rounded-lg`}
              >
                <Font
                  className={`text-sm ${
                    postDescription == "" ? "text-gray-600" : "text-green-600"
                  }`}
                >
                  Post
                </Font>
              </Pressable>
            </TouchableOpacity>
            <View className="py-2 flex-row flex-1">
              <View className="flex-row mr-2.5">
                <Image
                  source={require("../../../assets/user.jpg")}
                  className="w-8 h-8 rounded-full"
                />
              </View>
              <View className="flex-col flex-1">
                <TouchableOpacity className="w-full focus:ring-1 ring-app-d">
                  <TextInput
                    className="w-full p-2 border border-gray-300 rounded-2xl"
                    placeholder="Write something..."
                    placeholderTextColor="#94a3b8"
                    multiline={true}
                    numberOfLines={4}
                    value={postDescription}
                    onChangeText={(e) => setIsPostDescription(e)}
                    style={{ outline: "none" }}
                  />
                </TouchableOpacity>
                <View className="border border-gray-300 rounded-xl w-full mt-2 focus:ring-1 ring-app-d">
                  <TextInput
                    className="w-full p-2"
                    placeholder="https link to external url"
                    placeholderTextColor="#94a3b8"
                    value={postExternalURL}
                    onChangeText={(e) => setPostExternalURL(e)}
                    style={{ outline: "none" }}
                  />
                </View>
              </View>
            </View>
          </View>

          <View className=" flex-row p-2 w-full justify-center item-center border-t border-t-gray-300">
            <Pressable
              onPress={openFilePicker}
              className="py-1 px-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300"
            >
              <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                <View className="flex justify-center items-center">
                  <RemixIcon
                    name="ri-file-text-fill"
                    size={16}
                    color={"#EA3EF7"}
                    className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                  />
                </View>
                <Font className="lg:ml-4 text-sm">File</Font>
              </View>
            </Pressable>
            <Pressable
              onPress={openGallery}
              className="py-1 px-2 ml-2 mr-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300"
            >
              <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                <View className="mr-2 lg:mr-4 flex justify-center items-center">
                  <RemixIcon
                    name="ri-image-fill"
                    size={16}
                    color={"#75F94C"}
                    className="mr-1 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                  />
                </View>
                <Font className="lg:ml-4 text-sm">Gallery</Font>
              </View>
            </Pressable>
            <Pressable
              onPress={openCamera}
              className="py-1 px-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300"
            >
              <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                <View className="mr-2 lg:mr-4 flex justify-center items-center">
                  <RemixIcon
                    name="ri-camera-fill"
                    size={16}
                    color={"#0021F5"}
                    className="mr-1 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                  />
                </View>
                <Font className="lg:ml-4 text-sm">Camera</Font>
              </View>
            </Pressable>
          </View>
          {selectedFile != "" && selectedFile != null && (
            <View className="w-full  h-16 mb-5 bg-gray-200 rounded-md">
              <Pressable
                onPress={() => {
                  setSelectedFile(null);
                  setSelectedFileObj(null);
                }}
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  zIndex: 9999,
                  backgroundColor: "white",
                }}
                className={`w-5 h-5 rounded-full justify-center items-center`}
              >
                <Text style={{ fontSize: 14, fontWeight: "600" }}>X</Text>
              </Pressable>
              <View className="flex-col h-full items-center justify-center">
                <RemixIcon
                  name="ri-file-text-fill"
                  size={18}
                  color={"#EA3EF7"}
                  className=" w-5 h-5 lg:w-8  flex-shrink-0 mb-2"
                />
                <Text
                  className="flex items-center justify-center"
                  style={{ fontSize: 14, fontWeight: "600" }}
                >
                  {selectedFileName}
                </Text>
              </View>
              {/* <Image
                    source={{ uri: selectedImage }}
                    className="w-full h-full rounded-lg"
                    // style={{ resizeMode: "contain" }}
                  /> */}
            </View>
          )}
          {selectedImage != "" && selectedImage != null && (
            <View className="w-full h-56">
              <Pressable
                onPress={() => {
                  setSelectedImage(null);
                  setSelectedImageObj(null);
                }}
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  zIndex: 9999,
                  backgroundColor: "white",
                }}
                className={`w-5 h-5 rounded-full justify-center items-center`}
              >
                <Text style={{ fontSize: 14, fontWeight: "600" }}>X</Text>
              </Pressable>
              <Image
                source={{ uri: selectedImage }}
                className="w-full h-full rounded-lg"
                // style={{ resizeMode: "contain" }}
              />
            </View>
          )}
        </View>
        {/* <Footer navigation={navigation} /> */}
      </View>
      {showCameraView ? (
        <CameraCustomView
          onImageCapture={(result) => {
            let path =
              Platform.OS === "ios"
                ? result?.uri?.replace("file:///", "file:/")
                : result?.uri;
            setSelectedImage(result.uri);
            if (result.uri !== null) {
              fileURLtoBlob(path)
                .then((data) => {
                  result.blob = data;
                  setSelectedImageObj(result);
                })
                .catch((error) => {
                  console.log("fileURLtoBlob-error", error);
                });
            }
            setShowCameraView(false);
            setSelectedImage(result?.uri);
            setSelectedImageObj(result);
          }}
          onRequestClose={() => {
            setShowCameraView(false);
          }}
          onDismiss={() => {
            setShowCameraView(false);
          }}
        />
      ) : (
        <></>
      )}
    </ScrollView>
  );
}
